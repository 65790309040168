import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { People } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  pax?: string
  pretitle?: string
  title?: string
  fitness?: Boolean
  index?: number
}

export const Item = memo(function Item({
  cta,
  description,
  image,
  pax,
  pretitle,
  title,
  fitness,
  index = 0,
}: Props) {
  return (
    <Container>
      <FadeInUp delay={index * 0.2}>
        <Wrapper>
          {image ? <StyledImage {...image} /> : null}
          <Inner className={fitness ? 'fitness' : undefined}>
            {pretitle ? <Pretitle>{pretitle}</Pretitle> : null}
            {title ? <Title>{title}</Title> : null}
            {!fitness && description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {pax ? (
              <Pax>
                <People />
                {pax}
              </Pax>
            ) : null}
            {cta ? <StyledButton {...cta} /> : null}
          </Inner>
        </Wrapper>
        {fitness && description ? (
          <Description
            className={fitness ? 'fitness' : undefined}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    margin-right: 4.16vw;
  }

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 42.36vw;
  border-radius: 0.69vw;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }

  @media (max-width: 991px) {
    height: 50vh;
    border-radius: 6px;
    margin-bottom: 10px;
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Inner = styled.div`
  position: absolute;
  bottom: 4.5rem;
  left: 0;
  width: 100%;
  min-height: 9.722vw;
  padding: 0 5vw;
  z-index: 2;

  &.fitness {
    text-align: center;
  }

  @media (max-width: 1439px) {
    padding: 0 60px;
  }
  @media (max-width: 991px) {
    min-height: auto;
    bottom: 30px;
    padding: 0 30px;
  }
`

const Pretitle = styled.div`
  margin-bottom: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 2.22vw;
  line-height: 1.3;

  @media (max-width: 1439px) {
    font-size: 22px;
  }
`

const Description = styled.div`
  margin-top: 0.278vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }

  &.fitness {
    margin-top: 0;
    padding: 2.7vw 2.7vw 0;
    text-align: center;
  }
`

const Pax = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
  }

  svg {
    width: auto;
    height: 1.8vw;
    margin-right: 1.38vw;
    fill: ${({ theme }) => theme.colors.variants.primaryLight};

    @media (max-width: 1439px) {
      height: 26px;
      margin-right: 20px;
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 2.63vw;
`
